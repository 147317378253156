import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseDatos: [],
    esmovil: '',
    permisos: [],
    observaciones: [],
    array_carrito: [],
    dialogocontadores: null,
    dialogomedidas: null,
    dialogoFecha: null,
    dialogoFecha2: null,
    dialogoFecha3: null,
    dialogoConfiguracion: null,
    configuracion: [],
    impresoras_restau: [],
    configImpresora: {},
    seriesdocumentos: {},
    dialgoGestionMesas: null,
    dialogoToken: null,
    dialogoAgregaExcel: null,
    dialogoCartaQR: null,
    dialogoLogoEmpresa: null,
    dialogoImpresora: null,
    dialogoProducto: null,
    dialogoSerieDocumentos: null,
    dialogomodopago: null,
    dialogomensaje: null,
    dialogoAlerta: null,
    dialogoRestaurante: null,
    dialogoiconos: null,
    dialogoproductos: null,
    dialogoprogress: null,
    dialogosnackbar: null,
    dialogoinsumo: null,
    dialogobancos: null,
    dialogohost: null,
    dialogoPredefinidos: null,
    dialogoaPaletaColores: null,
    dialogo_cobrar: null,
    dialogo_caja: false,
    dialogoentrada: null,
    dial_carrito: false,
    colorselecto: '',
    logoempresa: '',
    textosnackbar: '',
    tipodialogoicono: '',
    iconoselecto: '',
    lista_modos: ['EFECTIVO', 'YAPE', 'PLIN', 'TARJETA', 'TRANSFERENCIA', 'T.DEBITO', 'T.CREDITO', 'RAPPY', 'PEDIDOS YA'],
    modopagos: [],
    iconos_pagos: [
      {
        nombre: 'EFECTIVO',
        icono: 'cash.png'
      },
      {
        nombre: 'TARJETA',
        icono: 'tarjeta.png'
      },
      {
        nombre: 'YAPE',
        icono: 'yape.png'
      },
      {
        nombre: 'PLIN',
        icono: 'plin.webp'
      },
      {
        nombre: 'TRANSFERENCIA',
        icono: 'bank.png'
      },
      {
        nombre: 'T.DEBITO',
        icono: 'tarjeta.png'
      },
      {
        nombre: 'T.CREDITO',
        icono: 'tarjeta_.png'
      },
      {
        nombre: 'RAPPY',
        icono: 'rappy.png'
      },
      {
        nombre: 'PEDIDOS YA',
        icono: 'pedidosya.png'
      },
    ],
    bancos: [],
    token: '',
    empresaselecta: [],
    arraynotifica: [],
    productos: [],
    clientes: [],
    categorias: [],
    productos_caja: [],
    cabecera_caja: [],
    modopago_caja: [],
    completa_cobro: false,
    productos_consolida: [],
    ultimo_correlativo: '',
    dialogoClientesnuevos: false,
    dialogoNota: null,
    data_caja_cabecera: '',
    data_caja: '',
    motivos_ajuste: ["AJUSTE DE INVENTARIO", "CONSUMO", "DETERIORO", "DONACION", "PERDIDA", "ROBO", "TRANSFORMACION"],
    medidas: ["UNIDAD", "DOCENA", "MILLARES", "KILOGRAMO", "METRO", "GRAMO", "PAQUETE", "CAJA", "BOTELLAS", "LITRO", "GALON", "BIDON"],
    medidassunat: [
      {
        nombre: "UNIDAD",
        corto: "NIU",
        general: "UND"
      },
      {
        nombre: "DOCENA",
        corto: "DZN",
        general: "DOC"
      },
      {
        nombre: "MILLARES",
        corto: "MIL",
        general: "MILL"
      },
      {
        nombre: "KILOGRAMO",
        corto: "KGM",
        general: "KG"
      },
      {
        nombre: "METRO",
        corto: "MTR",
        general: "MT"
      },
      {
        nombre: "GRAMO",
        corto: "GRM",
        general: "GR"
      },
      {
        nombre: "PAQUETE",
        corto: "PK",
        general: "PAQ"
      },
      {
        nombre: "CAJA",
        corto: "BX",
        general: "CAJ"
      },
      {
        nombre: "BOTELLAS",
        corto: "BO",
        general: "BOT"
      },
      {
        nombre: "LITRO",
        corto: "LTR",
        general: "LT"
      },
      {
        nombre: "GALON",
        corto: "GLI",
        general: "GAL"
      },
      {
        nombre: "BIDON",
        corto: "BO",
        general: "BID"
      },
    ],
    motivos: ["Anulación de la operación", "Anulación por error en el RUC", "Corrección por error en la descripción",
      "Descuento global", "Descuento por ítem", "Devolución total", "Devolución por ítem",
      "Bonificación", "Disminución en el valor", "Otros Conceptos"],
    motivosSunat: [
      {
        nombre: "Anulación de la operación",
        codigo: "01",
      },
      {
        nombre: "Anulación por error en el RUC",
        codigo: "02",
      },
      {
        nombre: "Corrección por error en la descripción",
        codigo: "03",
      },
      {
        nombre: "Descuento global",
        codigo: "04",
      },
      {
        nombre: "Descuento por ítem",
        codigo: "05",
      },
      {
        nombre: "Devolución total",
        codigo: "06",
      },
      {
        nombre: "Devolución por ítem",
        codigo: "07",
      },
      {
        nombre: "Bonificación",
        codigo: "08",
      },
      {
        nombre: "Disminución en el valor",
        codigo: "09",
      },
      {
        nombre: "Otros Conceptos",
        codigo: "10",
      }
    ],
    mesa_selecta:'TODAS'
  },
  mutations: {
    BD(state, n) {
      state.baseDatos = n
      console.log("STORE: " + n.bd)
    },
    esmovil(state, n) {
      state.esmovil = n
    },
    impresoras_restau(state, n) {
      state.impresoras_restau = n
    },
    mesa_selecta(state, n) {
      state.mesa_selecta = n
    },
    permisos(state, n) {
      state.permisos = n
    },
    configuracion(state, n) {
      state.configuracion = n
    },
    productos(state, n) {
      state.productos = n
    },
    clientes(state, n) {
      state.clientes = n
    },
    categorias(state, n) {
      state.categorias = n
    },
    array_carrito(state, n) {
      state.array_carrito = n
    },
    observaciones(state, n) {
      state.observaciones = n
    },
    configImpresora(state, n) {
      state.configImpresora = n
    },
    seriesdocumentos(state, n) {
      state.seriesdocumentos = n
    },
    dial_carrito(state, n) {
      state.dial_carrito = n
    },
    dialogocontadores(state) {
      state.dialogocontadores = !state.dialogocontadores
    },
    dialogomedidas(state) {
      state.dialogomedidas = !state.dialogomedidas
    },
    dialogoFecha(state) {
      state.dialogoFecha = !state.dialogoFecha
    },
    dialogomodopago(state) {
      state.dialogomodopago = !state.dialogomodopago
    },
    dialogoFecha2(state) {
      state.dialogoFecha2 = !state.dialogoFecha2
    },
    dialogoFecha3(state) {
      state.dialogoFecha3 = !state.dialogoFecha3
    },
    dialogoConfiguracion(state) {
      state.dialogoConfiguracion = !state.dialogoConfiguracion
    },
    dialgoGestionMesas(state) {
      state.dialgoGestionMesas = !state.dialgoGestionMesas
    },
    dialogoToken(state) {
      state.dialogoToken = !state.dialogoToken
    },
    dialogoAgregaExcel(state) {
      state.dialogoAgregaExcel = !state.dialogoAgregaExcel
    },
    dialogoCartaQR(state) {
      state.dialogoCartaQR = !state.dialogoCartaQR
    },
    dialogoLogoEmpresa(state) {
      state.dialogoLogoEmpresa = !state.dialogoLogoEmpresa
    },
    dialogoImpresora(state) {
      state.dialogoImpresora = !state.dialogoImpresora
    },
    dialogoProducto(state) {
      state.dialogoProducto = !state.dialogoProducto
    },
    dialogoSerieDocumentos(state) {
      state.dialogoSerieDocumentos = !state.dialogoSerieDocumentos
    },
    dialogoRestaurante(state) {
      state.dialogoRestaurante = !state.dialogoRestaurante
    },
    dialogoiconos(state, n) {
      state.dialogoiconos = !state.dialogoiconos
      state.tipodialogoicono = n
    },
    dialogoproductos(state) {
      state.dialogoproductos = !state.dialogoproductos
    },
    dialogoPredefinidos(state) {
      state.dialogoPredefinidos = !state.dialogoPredefinidos
    },
    iconoselecto(state, n) {
      state.iconoselecto = n
    },
    token(state, n) {
      state.token = n
    },
    bancos(state, n) {
      state.bancos = n
    },
    modopagos(state, n) {
      state.modopagos = n
    },
    empresaselecta(state, n) {
      state.empresaselecta = n
    },
    dialogomensaje(state) {
      state.dialogomensaje = !state.dialogomensaje
    },
    dialogoAlerta(state) {
      state.dialogoAlerta = !state.dialogoAlerta
    },
    arraynotifica(state, n) {
      state.arraynotifica = n
    },
    dialogoprogress(state) {
      state.dialogoprogress = !state.dialogoprogress
    },
    dialogosnackbar(state, n) {
      state.dialogosnackbar = !state.dialogosnackbar
      state.textosnackbar = n
    },
    dialogoinsumo(state) {
      state.dialogoinsumo = !state.dialogoinsumo
    },
    dialogobancos(state) {
      state.dialogobancos = !state.dialogobancos
    },
    dialogohost(state) {
      state.dialogohost = !state.dialogohost
    },
    dialogoaPaletaColores(state) {
      state.dialogoaPaletaColores = !state.dialogoaPaletaColores
    },
    logoempresa(state, n) {
      state.logoempresa = n
    },
    colorselecto(state, n) {
      state.colorselecto = n
    },
    dialogo_cobrar(state) {
      state.dialogo_cobrar = !state.dialogo_cobrar
    },
    productos_caja(state, n) {
      state.productos_caja = n
    },
    modopago_caja(state, n) {
      state.modopago_caja = n
    },
    cabecera_caja(state, n) {
      state.cabecera_caja = n
    },
    productos_consolida(state, n) {
      state.productos_consolida = n
    },
    dialogo_caja(state) {
      state.dialogo_caja = !state.dialogo_caja
    },
    ultimo_correlativo(state, n) {
      state.ultimo_correlativo = n
    },
    completa_cobro(state, n) {
      state.completa_cobro = !state.completa_cobro
    },
    dialogoClientesnuevos(state) {
      state.dialogoClientesnuevos = !state.dialogoClientesnuevos
    },
    dialogoNota(state) {
      state.dialogoNota = !state.dialogoNota
    },
    data_caja(state, n) {
      state.data_caja = n
    },
    data_caja_cabecera(state, n) {
      state.data_caja_cabecera = n
    },
    dialogoentrada(state) {
      state.dialogoentrada = !state.dialogoentrada
    },
  },
  actions: {
  },
  modules: {
  }
})
